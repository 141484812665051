import React, { useRef, useEffect, useContext } from 'react';
import { fabric } from 'fabric';
import Locations from './Locations'
import Layouts from './Layouts'
import { EditorContext } from '../../contexts/editorContext'
import { CommContext } from '../../contexts/commContext'
import config from '../../config.json'
import axios from 'axios'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FabricView = () => {
  const canvas = useRef(null)
  const bookDateRef = useRef(null)
  const { layout, bookDate, setBookDate, bookAvailability, setBookAvailability } = useContext(EditorContext)
  const { ioClient, ioConnected } = useContext(CommContext)
  useEffect(() => {
    const updateEntityAvailability = (data) => {
      console.log(data)
    }
    if(ioClient == null) return
    if(ioConnected) {
      ioClient.on('entityAvailabilityUpdated', updateEntityAvailability)
    } else {
      ioClient.off('entityAvailabilityUpdated', updateEntityAvailability)
    }
  }, [ioClient, ioConnected])

  useEffect(() => {
    async function getLayoutFabric() {
      try {
        let body = {
          layoutID: 1
        }
        const response = await axios.get(`${config.apiBaseUrl}api/v1/layout`, {
          params: body
        })
        canvas.current.loadFromJSON(response.data.data.fabric)
        let objects = canvas.current.getObjects()
        for(let object of objects) {
          object.selectable = false
          object.hoverCursor = 'pointer'
        }
        canvas.current.selection = false
      } catch (error) {
        console.log(error)
      }
    }
    getLayoutFabric()
  }, [layout, canvas])
  
  useEffect(() => {
    bookDateRef.current = bookDate
  }, [bookDate])

  useEffect(() => {
    async function getLayoutAvailability() {
      try {
        let body = {
          layoutID: 1,
          date: bookDate
        }
        const response = await axios.get(`${config.apiBaseUrl}api/v1/availability/layout`, {
          params: body
        })
        setBookAvailability(response.data.data)
      } catch (error) {
        console.log(error)
      }
    }
    getLayoutAvailability()
  }, [layout, canvas, bookDate, setBookAvailability])

  useEffect(() => {
    if(canvas.current == null) return
    canvas.current.getObjects().forEach(function(o) {
      if(bookAvailability[o.id]['9']?.segments != null && bookAvailability[o.id]['9']?.segments['00']?.available >= 48) {
        o.set('fill', 'green')
      } else {
        o.set('fill', 'red')
      }
    })
    canvas.current.renderAll()
  }, [canvas, bookAvailability])

  useEffect(() => {
    canvas.current = initCanvas();
  }, []);

  const initCanvas = () => {
    let canv = new fabric.Canvas('canvas', {
      height: 800,
      width: 800,
      backgroundColor: 'pink'
    })
    canv.on({
      'mouse:dblclick': function(e) {
        console.log(e)
      }});
    canv.on({
      'mouse:up': function(e) {
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
        this.selection = true;
        if(this.hadMoved) {
          this.hadMoved = false
          return
        }
        console.log(e.target)
        async function reserveTemporary(entityID, bookDuration) {
          try {
            let body = {
              booking: {
                entityID,
                bookDate: bookDateRef.current,
                bookDuration
              }
            }
            const response = await axios.post(`${config.apiBaseUrl}api/v1/booking/reserveTemporary`, body)
            console.log(response)
          } catch (error) {
            console.log(error)
          }
        }
        if(e.target == null) return null
        reserveTemporary(e.target.id, 12)
      }
    });
    canv.on('mouse:down', function(opt) {
      var evt = opt.e;
      this.isDragging = true;
      this.selection = false;
      this.lastPosX = evt.clientX;
      this.lastPosY = evt.clientY;
    });
    canv.on('mouse:move', function(opt) {
      if (this.isDragging) {
        this.hadMoved = true
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    })
    canv.on('mouse:wheel', function(opt) {
      var delta = opt.e.deltaY;
      var zoom = canv.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 5) zoom = 5;
      if (zoom < 0.5) zoom = 0.5;
      canv.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
    return canv
  }
  return(
    <div>
      <Locations />
      <Layouts />
      <DatePicker selected={bookDate} showTimeSelect onChange={date => setBookDate(date)} />
      <h1>Location booking view</h1>
      <canvas id="canvas" />
    </div>
  );
}

export default FabricView;
