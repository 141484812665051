import { useContext, useEffect } from "react"

import EntitiesList from "./list"
import { EditorContext } from "../../../contexts/editorContext"
import axios from 'axios'
import config from '../../../config.json'

function reduceEntities (rows) {
  let final = {}
  for (let row of rows) {
    final[`entity_${row.id}`] = row
  }
  return final
}

export default function Entities(props) {
    const { setEntities, layout } = useContext(EditorContext);

    const onAddButtonClick = (entity) => {
      if(typeof props.onAddButtonClick === 'function') {
        props.onAddButtonClick(entity)
      } else {
        console.log(entity)
      }
    }

    useEffect(() => {
        async function getLayoutEntities () {
            try {
              let body = { 
                layoutID: 1
              }
              const response = await axios.get(`${config.apiBaseUrl}api/v1/entities/layout`, {params: body});
              console.log(response.data.data.entities)
              setEntities(reduceEntities(response.data.data.entities))
            } catch (error) {
              console.log(error)
            }
          }
          getLayoutEntities()
    }, [setEntities, layout])
    return <EntitiesList onAddButtonClick={onAddButtonClick} />
}