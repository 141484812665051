import { useState, createContext, useMemo } from 'react'

const BookingContext = createContext()

const BookingProvider = (props) => {
    const [entities, setEntities] = useState({})
    const [locations, setLocations] = useState({})
    const [layouts, setLayouts] = useState({})
    const [layout, setLayout] = useState(null)
    const [location, setLocation] = useState(1)

    const value = useMemo(() => ({entities, setEntities}),[entities])
    const value2 = useMemo(() => ({locations, setLocations}),[locations])
    const value3 = useMemo(() => ({layouts, setLayouts}),[layouts])
    const value4 = useMemo(() => ({layout, setLayout}),[layout])
    const value5 = useMemo(() => ({location, setLocation}),[location])

    return (
        <BookingContext.Provider
            value={{...value, ...value2, ...value3, ...value4, ...value5}}
        >
            {props.children}
        </BookingContext.Provider>
    )
}

export { BookingContext, BookingProvider }