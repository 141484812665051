import { useContext, useEffect } from "react"

import LayoutsList from "./list"
import { EditorContext } from "../../../contexts/editorContext"
import axios from 'axios'
import config from '../../../config.json'

export default function Layouts(props) {
    const { layout } = useContext(EditorContext);

    const onLayoutSelected = (layout) => {
      if(typeof props.onLayoutSelected === 'function') {
        props.onLayoutSelected (layout)
      } else {
        console.log(layout)
      }
    }

    useEffect(() => {
        async function getLayout () {
            try {
              let body = { 
                layoutID: 1
              }
              const response = await axios.get(`${config.apiBaseUrl}api/v1/layout/`, {params: body});
              console.log(response.data.data)
              //getLayout(layout)
            } catch (error) {
              console.log(error)
            }
          }
          getLayout()
    }, [layout])
    return <LayoutsList onLayoutSelected={onLayoutSelected} />
}