import { useState, useEffect, useRef, useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
//import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import config from '../../config.json'
import DataTable from '../../components/DataTable'
import moment from 'moment'
import LayoutSelector from '../../components/LayoutSelector'
//import Select from '@material-ui/core/Select'
import CountButton from '../../components/CountButton'
import { CommContext } from '../../contexts/commContext'
import { BookingContext } from '../../contexts/bookingContext'
import merge from 'lodash.merge'
import DateTimePicker from 'react-datetime-picker'
import { toast } from 'react-toastify'
import './style.scss'
import { PermissionWrapper, permission } from '../PermissionWrapper'


export default function LocalBookingDialog(props) {

  const { ioClient, ioConnected } = useContext(CommContext)
  const { location } = useContext(BookingContext)
  const [open, setOpen] = useState(false)
  const [entities, setEntities] = useState([])
  const [layout, setLayout] = useState({})
  const [newEntity, setNewEntity] = useState({})
  const [bookName, setBookName] = useState('')
  const [bookEmail, setBookEmail] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [totalPrice, setTotalPrice] = useState(0)
  const [entityType, setEntityType] = useState(localStorage['local-booking-dialog-selected-entity-type'] != null ? parseInt(localStorage['local-booking-dialog-selected-entity-type']) : 300)

  const newEntityRef = useRef({})
  const selectedDateRef = useRef(new Date())

  const calcTotalPrice = () => {
    let total = 0
    // eslint-disable-next-line
    for (let [key, value] of Object.entries(newEntityRef.current)) {
      total += value.seats * value.price
    }
    setTotalPrice(total)
  }

  const formatEntities = () => {
    //console.log(newEntityRef.current)
    let entities1 = []
    // eslint-disable-next-line
    for (let [key, value] of Object.entries(newEntityRef.current)) {
      if (value.seats > 0) {
        entities1.push({ ...value })
      }
    }

    return entities1
  }

  const handleEntitySelect = (e) => {
    let splitName = e.target.name.split('_')
    newEntityRef.current[`${e.target.name}`] = {
      id: splitName[1],
      seats: e.target.value,
      price: getActiveAvailabilitySegment(entities[splitName[1]], selectedDateRef.current)?.seatPrice || entities[splitName[1]].seatPrice,
    }
    setNewEntity({ ...newEntityRef.current })
  }

  const addEntity = (name) => {
    handleEntitySelect({ target: { name, value: 1 } })
  }

  const onEntityCountChange = (params, name) => {
  
    handleEntitySelect({ target: { name, value: params.count } })
  }

  const handleEntityType = (type) => {
    localStorage['local-booking-dialog-selected-entity-type'] = type
    setEntityType(type)
  }

  function digestLayout(layout) {
    for (let ent of Object.values(layout)) {
      merge(ent, ent.entity)
    }
    return layout
  }

  function digestEvents(events) {

    let eventObject = {}
    for (let event of events) {
      eventObject[event.entity.id] = { ...event.entity, ...event }
    }

    return eventObject
  }

  function getActiveAvailabilitySegment(row, date) {
    if (row == null) return {}
    let hour = +moment(date).format('HH')
    let minute = (Math.floor(+moment(date).format('mm') / 15) * 15 + '').padStart(2, '0')

    if (row.timeForceFromStartAlways === 1 || (!moment().isSame(date, 'date') && row.timeForceFromStart)) {
      hour = row.firstOpenSegment.hour
      minute = row.firstOpenSegment.minute
    }

    try {
      let res = { ...row.availability[hour].segments[minute], hour, minute }
      return res
    } catch (e) {
      return { hour, minute }
    }
  }

  useEffect(() => {
    async function getEntities() {
      if (entityType === 200 && layout != null) {
        try {
          let body = {
            date: moment(selectedDate).format('YYYY-MM-DD'),
            layoutID: 1,
          }
          const response = await axios.get(
            `${config.apiBaseUrl}api/v1/availability/layout`,
            { params: body }
          )
          newEntityRef.current = {}
          setNewEntity({})
          setEntities(digestLayout(response.data.data))
        } catch (error) {
          console.log(error)
        }
      }

      if (entityType === 300) {
        try {
          let date = moment(selectedDate).format('YYYY-MM-DD')
          let body = {
            fromDate: date,
            toDate: date,
          }
          const response = await axios.get(
            `${config.apiBaseUrl}api/v1/availability/events`,
            { params: body }
          )
          newEntityRef.current = {}
          setNewEntity({})
          setEntities(digestEvents(response.data.data))
        } catch (error) {
          console.log(error)
        }
      }
    }

    if (open) getEntities()
    if (ioConnected) {
      ioClient.on('entityUpdated', getEntities)
    }
  }, [selectedDate, ioConnected, ioClient, layout, entityType, open])

  useEffect(() => {
    if (props.entityType == null) return
    setEntityType(props.entityType)
  }, [props.entityType])

  useEffect(() => {
    if (props.date == null) return
    setSelectedDate(new Date(props.date))
  }, [props.date])

  useEffect(() => {
    calcTotalPrice()
  }, [newEntity])

  useEffect(() => {
    selectedDateRef.current = selectedDate
  }, [selectedDate])

  const columns = [
    {
      name: 'id',
      label: '#',
      options: {
        className: "head-align-center cell-align-center",
        format: false
      }
    },
    {
      name: 'name',
      label: 'Termék',
      options: {
        className: "head-align-center",
        format: row => {
          return <strong>{row.name.hu} ({getActiveAvailabilitySegment(row, selectedDate)?.hour}:{getActiveAvailabilitySegment(row, selectedDate)?.minute})</strong>
        },
      }
    },
    {
      name: 'free',
      label: 'Elérhető',
      options: {
        className: "head-align-center cell-align-center",
        format: row => {
          let availableSeats = getActiveAvailabilitySegment(row, selectedDateRef.current)?.availableSeats
          return (<strong>
            {availableSeats || '0'}
          </strong>)
        },
      }
    },
    {
      name: 'book',
      label: 'Foglalás',
      options: {
        className: "head-align-center cell-align-center",
        format: row =>
          <CountButton 
            id={`entity_${row.id}`}
            name={`entity_${row.id}`}
            onChange={(e) => { onEntityCountChange(e, `entity_${row.id}`) }} 
            count={newEntity[`entity_${row.id}`]?.seats || 0}
            addItem={() => { addEntity(`entity_${row.id}`) }}
            maxValue={999}
          />
        }
    },
    {
      name: 'seatPrice',
      label: 'Ár',
      options: {
        className: "head-align-center cell-align-right",
        format: row =>
        <strong>
          {parseInt(getActiveAvailabilitySegment(row, selectedDateRef.current)?.seatPrice || row.seatPrice)} Ft
        </strong>
      }
    },
    {
      name: 'totalPrice',
      label: 'Totál',
      options: {
        className: "head-align-center cell-align-right",
        format: row => 
          <strong>
            {(getActiveAvailabilitySegment(row, selectedDateRef.current)?.seatPrice || row.seatPrice) *
              (newEntity[`entity_${row.id}`]?.seats || 0)} Ft
          </strong>
      }
    },
  ]

  useEffect(() => { }, [props.entities])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const submitBooking = async () => {
    try {
      let body = {
        booking: {
          bookDate: moment(selectedDateRef.current).format('YYYY-MM-DD HH:mm'),
          bookDuration: 60,
          bookGuests: 1,
          bookName,
          bookEmail,
          bookPhone: null,
          bookDescription: null,
          invoiceInfo: {
            tax: '',
            zip: '',
            city: '',
            name: '',
            floor: '',
            state: '',
            street: '',
            taxType: 'NO_TAX_NUMBER',
            countryCode: 'HU',
            streetNumber: '',
          },
          entities: formatEntities(),
        },
        paymentAgent: 'local'
      }

      const response = await axios.post(
        `${config.apiBaseUrl}api/v1/manager/booking`,
        body
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    submitBooking()
      .then((res) => {
        if (res.success) {
          toast.success("Sikeres foglalás!")
          setOpen(false)
        } else {
          toast.error("Sikertelen foglalás!")
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }
  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  return (
      <PermissionWrapper permissions={[permission.EventSales, permission.EntitySales]} onlyAdmin={false} locationID={location} noPermission={<p></p>} >
        <div className="booking-container">
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            {props.label}
          </Button>
        </div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <div className="booking-dialog-container">
            {/*<DialogTitle id="form-dialog-title">
              Az új foglalás részletei
            </DialogTitle>*/}
            
            
            <div className="entity-type-select">
              <div style={{display: "flex"}}>
                <PermissionWrapper permissions={[permission.EntitySales]} onlyAdmin={false} locationID={location} noPermission={<p></p>}>
                  <div className={`btn product-button ${entityType === 200 ? 'selected' : ''}`} onClick={() => { handleEntityType(200) }}>termékek</div>
                </PermissionWrapper>
                <PermissionWrapper permissions={[permission.EventSales]} onlyAdmin={false} locationID={location} noPermission={<p></p>}>
                  <div className={`btn event-button ${entityType === 300 ? 'selected' : ''}`} onClick={() => { handleEntityType(300) }}>események</div>
                </PermissionWrapper>
              </div>
              <div className="actions" style={{display: "flex", justifyContent: "flex-end", padding: "3px"}}>
                <Button type='submit' form="form" variant="contained" color="primary">
                  Rendben
                </Button>
                <Button onClick={handleClose} variant="contained" color="secondary">
                  Vissza
                </Button>
              </div>
            </div>
            <div className="form-container">
              <form id="form" autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                <DialogContent>
                  <div className="booking-dialog-content">
                    <div className="booking-header-container">
                      {entityType === 200 && <LayoutSelector name="localBooking" setLayout={setLayout} />}
                      <DateTimePicker
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        format={"y-MM-dd HH:mm"}
                      />
                      <TextField name='bookName' label="Vendég neve" variant="outlined" required value={bookName} onChange={(e) => setBookName(e.target.value)} />
                      <TextField name='bookEmail' label="Vendég email" variant="outlined" required value={bookEmail} onChange={(e) => setBookEmail(e.target.value)} />
                    </div>
                    <div className="table-container" style={{height: "calc(100% - 156px)"}}>
                      <DataTable
                        data={Object.values(entities)}
                        columns={columns}
                      />
                    </div>
                    <h2>Totál: {totalPrice}</h2>
                  </div>
                </DialogContent>
              </form>
            </div>
          </div>
        </Dialog>
      </PermissionWrapper>
  )
}
