import React, { useState, useEffect, useContext } from 'react'
import { fabric } from 'fabric'
import Entities from './Entities'
import Locations from './Locations'
import Layouts from './Layouts'
import { EditorContext } from '../../contexts/editorContext'
import config from '../../config.json'
import axios from 'axios'
import { PermissionWrapper } from '../../components/PermissionWrapper'

/*function svgToObject(svgPath) {
  return new Promise((resolve, reject) => {
    fabric.loadSVGFromURL(svgPath, function (objects, options) {
      var obj = fabric.util.groupSVGElements(objects, options)
      obj.scale(0.5)
      resolve(obj)
    })
  })
}*/

fabric.EntityObject = fabric.util.createClass(fabric.Rect, {
  type: 'entityObject',
  // initialize can be of type function(options) or function(property, options), like for text.
  // no other signatures allowed.
  initialize: function (options) {
    options || (options = {})
    this.callSuper('initialize', options)
    this.set('name', options.label || '')
    this.set('id', options.id || '')
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      label: this.get('name'),
      id: this.get('id'),
    })
  },

  _render: function (ctx) {
    this.callSuper('_render', ctx)
    ctx.font = '20px Helvetica'
    ctx.fillStyle = '#FFF'
    ctx.fillText(this.name, -this.width / 2, -this.height / 2 + 20)
  },
})

fabric.EntityObject.fromObject = function (object, callback) {
  return fabric.Object._fromObject('EntityObject', object, callback)
}

const addEntity = (canvi, entity) => {
  let existingObject = findObjectByID(canvi, entity.id)
  if (existingObject != null) {
    console.log('Already on canvas!!!')
    return
  }
  const rect = new fabric.EntityObject({
    height: 30,
    width: 150,
    fill: 'green',
    label: entity.name,
    id: entity.id,
    top: 100,
  })
  canvi.add(rect)
  canvi.renderAll()
}

const listItems = (canvi) => {
  console.log(canvi.getObjects())
}

const findObjectByID = (canvi, id) => {
  let objects = canvi.getObjects()
  for (let object of objects) {
    if (object.id === id) return object
  }
  return null
}

const color222 = (canvi) => {
  canvi.getObjects().forEach(function (o) {
    if (o.id === 222) {
      console.log('222')
      o.set('fill', 'black')
    }
  })
  canvi.renderAll()
}

const color333 = (canvi) => {
  canvi.getObjects().forEach(function (o) {
    if (o.id === 333) {
      canvi.setActiveObject(o)
    }
  })
}

const delActive = (canvi) => {
  let obj = canvi.getActiveObject()
  canvi.remove(obj)
}

const loadCanvas = (canvi) => {
  let objects = localStorage.getItem('canvas')
  console.log(JSON.parse(objects))
  canvi.loadFromJSON(JSON.parse(objects))
}

const FabricEditor = () => {
  const { layout, location } = useContext(EditorContext)
  const [canvas, setCanvas] = useState('')
  useEffect(() => {
    let canv = initCanvas()

    canv.on({
      'mouse:dblclick': function (e) {
        console.log(e)
      },
    })
    setCanvas(canv)
  }, [])

  useEffect(() => {
    async function getLayoutFabric() {
      try {
        let body = {
          layoutID: 1,
        }
        const response = await axios.get(`${config.apiBaseUrl}api/v1/layout`, {
          params: body,
        })
        console.log(response.data.data.fabric)
        canvas.loadFromJSON(response.data.data.fabric)
      } catch (error) {
        console.log(error)
      }
    }
    getLayoutFabric()
  }, [layout, canvas])

  async function setLayoutFabric() {
    try {
      let body = {
          layout: {
          id: layout,
          name: 'layout #' + layout,
          fabric: canvas.toObject()
        }
      }
      const response = await axios.post(`${config.apiBaseUrl}api/v1/layout`, body)
      console.log(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const initCanvas = () => {
    let canvi = new fabric.Canvas('canvas', {
      height: 800,
      width: 800,
      backgroundColor: 'pink',
    })

    return canvi
  }

  return (
    <PermissionWrapper permissions={[]} onlyAdmin={true} locationID={location.id} >
      <div>
        <Locations />
        <Layouts />
        <Entities onAddButtonClick={(entity) => addEntity(canvas, entity)} />
        <h1>Location layout editor</h1>
        <button onClick={() => listItems(canvas)}>List items</button>
        <button onClick={() => color222(canvas)}>222</button>
        <button onClick={() => color333(canvas)}>333</button>
        <button onClick={() => delActive(canvas)}>Del</button>
        <button onClick={() => setLayoutFabric()}>Save</button>
        <button onClick={() => loadCanvas(canvas)}>Load</button>
        <canvas id="canvas" />
      </div>
    </PermissionWrapper>
  )
}

export default FabricEditor
