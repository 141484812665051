import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import './style.scss'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import LocalBookingDialog from '../LocalBookingDialog'
//import LocationSelector from '../LocationSelector'
import { useLocation } from "react-router-dom"

function Navigator(props) {

    const [value, setValue] = useState(0)
    let location = useLocation()

    useEffect(() => {
        switch(location.pathname) {
            case '/bookings/list': setValue(0)
            break
            case '/bookings/entities': setValue(1)
            break
            case '/entities': setValue(2)
            break
            case '/reports': setValue(3)
            break
            default: 
                if(location.pathname.startsWith('/reports')) {
                    setValue(3)
                } else {
                    setValue(0)
                }
        }
    }, [location]);

    const handleTabSelect = (tabIndex) => {
        switch(tabIndex) {
            case 0: props.history.replace('/bookings/list')
            break
            case 1: props.history.replace('/bookings/entities')
            break
            case 2: props.history.replace('/entities')
            break
            case 3: props.history.replace('/reports')
            break
            default: props.history.replace('')
        }
    }

    const handleChange = (event, newValue) => {
        handleTabSelect(newValue)
        setValue(newValue)
    }

    return(
        <div className="nav-container">
            {/* <LocationSelector /> */}
            <span style={{color: "#fff"}}>LUPA BEACH</span>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs">
                <Tab label="Foglalások" />
                <Tab label="Termékek" />
                <Tab label="Események" />
                <Tab label="Riportok" />
            </Tabs>
            <LocalBookingDialog label='Foglalás' />
        </div>
    )
}

export default withRouter(Navigator)